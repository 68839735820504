import { Box } from '@mui/material'
import Image from 'next/image'

export default function Home(): JSX.Element {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        background:
          'linear-gradient(339.3deg, #029F9B 11.24%, #0C7D96 46.56%, #201E70 85.36%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box mb={3}>
        <Image
          src="/icons/beluga-logo.svg"
          alt="Beluga Whale"
          width={185}
          height={85}
        />
      </Box>
      <Image
        src="/icons/beluga-logo-text.svg"
        alt="Beluga Logo"
        width={182}
        height={64}
      />
    </Box>
  )
}
